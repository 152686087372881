import React from "react";
import {Grid, Pagination, PaginationItem, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {getFormattedProperty} from "../../utils/utils";
import {getTapeIcon} from "../Restore/tapesIcons";
import {TYPICAL_COLUMN_UI_CLASS} from "./tableCustomization";


/**
 * Helper function that generates the internal content used later to populate the tooltip of a table cell.
 * @param {Object | null | undefined} entity - The entity (Object) containing the raw value to be displayed in the tooltip (or null).
 * @param tooltTipList - The list of tooltip lines to be included.If entity is not provided, each tooltTipList entry needs to contain a value.
 * @returns {React.JSX.Element}
 */
export function dynamicTooltipConstruction(entity, tooltTipList) {
    const maxCharactersPerLine = 80; // Limit each line to 80 characters
    const maxTotalRows = 40; // Limit the total rows in the tooltip

    /**
     * Function to split long values into lines of 80 characters
     * @param value
     * @returns {*[]}
     */
    const wrapValue = (value) => {
        const lines = [];
        for (let i = 0; i < value.length; i += maxCharactersPerLine) {
            lines.push(value.slice(i, i + maxCharactersPerLine));
        }
        return lines;
    };

    // Build tooltip content and enforce the 40-row limit
    const rows = [];
    for (const tooltipEntry of tooltTipList) {
        const tooltipKey = tooltipEntry?.name;
        let tooltipEntryValue;
        if (entity) {
            tooltipEntryValue = getFormattedProperty(entity, tooltipEntry?.mnemonic, tooltipEntry?.ui_class);
        } else {
            tooltipEntryValue = getFormattedProperty(tooltipEntry?.value, null, tooltipEntry?.ui_class);
        }
        const tooltipEntryWrappedValue = wrapValue(tooltipEntryValue);

        tooltipEntryWrappedValue.forEach((line, index) => rows.push(
            <div key={index}>
                {index === 0 && tooltipKey ? (<strong>{tooltipKey} : </strong>) : ""}{line}
            </div>));
        if (rows.length >= maxTotalRows) break;
    }

    return <>{rows.slice(0, maxTotalRows)}</>;
}

/**
 * React component to render the pagination of the table.
 * @param {boolean} showPagination - A boolean to indicate if the pagination should be displayed.
 * @param {number} entriesCount - The number of entries in the table.
 * @param {number} currentPage - The current page of the table.
 * @param {number} totalPages - The total number of pages in the table.
 * @param {function} handlePageChangeCallback - A callback function to be called when the page is changed.
 * @returns {Element}
 * @constructor
 */
const TablePagination = ({
                             showPagination,
                             entriesCount,
                             currentPage,
                             totalPages,
                             handlePageChangeCallback
                         }) => {
    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, entriesCount);

    //Pagination
    return (showPagination ?
            <>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                        style={{
                            width: "100%",
                            border: "1px solid #C7CCD0",
                            minWidth: "55%",
                        }}>
                        <Grid item ml={2}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    fontWeight: 400,
                                    color: "#FFFFFF",
                                }}>
                                {`Showing ${entriesCount ? startIndex : 0}-${endIndex} of ${entriesCount} Items`}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Pagination
                                component="div"
                                rowsPerPage={10}
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChangeCallback}
                                sx={{
                                    "& .MuiPaginationItem-root": {
                                        width: "30px",
                                        height: "30px",
                                        border: "1px solid #FFFFFF",
                                        background: "#3A3E3F",
                                        color: "#FFFFFF",
                                        margin: "0 4px",
                                        "&.Mui-selected": {
                                            background: "#FFC540",
                                            color: "#3A3E3F",
                                        },
                                    },
                                    "& .MuiPaginationItem-page.Mui-selected:hover": {
                                        background: "#FFC540",
                                        color: "#3A3E3F",
                                    },
                                    "& .MuiPaginationItem-page:hover": {
                                        background: "#FFC540",
                                        color: "#3A3E3F",
                                    },
                                    "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                                        width: "100px",
                                        height: "40px",
                                        borderRadius: "8px",
                                        border: "1px solid #FFFFFF",
                                        color: "#FFFFFF",
                                        background: "#3A3E3F",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    },
                                    "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover": {
                                        background: "#FFC540",
                                        color: "#3A3E3F",
                                    },
                                }}
                                renderItem={(item) => <PaginationItem {...item} />}
                            />
                        </Grid>
                    </Grid>
                </Grid></> : <></>
    );

};

/**
 * Function to render a dynamic table component.
 * @param {Object[]} data - A list of objects containing the data to be displayed in the table.
 * @param {[*]} tableColumns - A list of objects containing the columns to be displayed in the table. Minimum details: name, mnemonic. Optional details: ui_class, special_render, searchable, tooltip.
 * @param {boolean} dataContainsFormatting - A boolean to indicate if the data contains formatting type, or it should be found in tableColumns.
 * @param {function | undefined} actionComponent - A function that generates a component to be displayed in the action column of the table (like and Add/Remove button).
 * @param {Object} componentsClickCallBackMap - A map of components and their respective callback functions to be called when the component is clicked.
 * @param {Object} classes - A list of classes to be used in the table.
 * @param {string | undefined} searchQuery - A string to be searched in the table data.
 * @param {boolean} showPagination - A boolean to indicate if the pagination should be displayed.
 * @param {number} entriesCount - The number of entries in the table.
 * @param {number} currentPage - The current page of the table.
 * @param {number} totalPages - The total number of pages in the table.
 * @param {function} handlePageChangeCallback - A callback function to be called when the page is changed.
 * @returns {Element}
 * @constructor
 */
const DataTable = ({
                       data,
                       tableColumns,
                       dataContainsFormatting,
                       actionComponent = undefined,
                       componentsClickCallBackMap,
                       classes,
                       searchQuery = undefined,
                       showPagination,
                       entriesCount,
                       currentPage,
                       totalPages,
                       handlePageChangeCallback
                   }) => {

    /**
     * Function to render a table cell for no data situation.
     * @returns {Element}
     */
    const noDataCell = () => {
            return <TableCell colSpan={2} align="centre">
                <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
            </TableCell>;
        }
    ;

    /**
     * Function to highlight the search query in the text. It generates components and the ones that match the search query, have the highlight class.
     * @param id - id not used
     * @param {string} text - Text to be compared with the search query
     * @returns - Array of components
     */
    const highlightText = (id, text) => {
        if (!searchQuery || searchQuery === "") {
            return text;
        }
        const regex = new RegExp(`(${searchQuery})`, "gi");
        const parts = text?.split(regex);
        return parts?.map((part) =>
            part?.toLowerCase() === searchQuery.toLowerCase() ? (
                <Typography sx={{fontSize: 14}} className={classes.highlight} component="span">
                    {part}
                </Typography>
            ) : (
                <Typography sx={{fontSize: 14}} component="span">{part}</Typography>
            )
        );
    };

    /**
     * helper function that generates the internal content used later to populate the content of a table cell
     * @param column
     * @param row
     * @returns {React.JSX.Element|string|*[]|*}
     */
    const dynamicContentForCellHelper = (column, row) => {
        let columnType;
        if (!dataContainsFormatting) {
            // get the ui_class from the column
            columnType = column?.ui_class;
        } else {
            // get the ui_class from the row
            if (row?.attributes && row.attributes?.[column.mnemonic]) {
                if (row?.attributes?.[column.mnemonic]?.ui_class) {
                    // get ui_class from attributes -> mnemonic
                    columnType = row.attributes[column.mnemonic].ui_class;
                } else {
                    // default to data_type from attributes -> mnemonic
                    columnType = row.attributes[column.mnemonic]["data_type"];
                }
            } else {
                // try getting the ui_class from TYPICAL_COLUMN_UI_CLASS if it exists or default to 'str'
                columnType = TYPICAL_COLUMN_UI_CLASS[column.mnemonic] ? TYPICAL_COLUMN_UI_CLASS[column.mnemonic] : 'str';
            }
        }

        const formattedProperty = getFormattedProperty(row, column.mnemonic, columnType);

        return column.special_render === "tape_photo" ?
            (
                row?.["media_type"] ?
                    (<img onClick={() => {
                        componentsClickCallBackMap["tape_photo"](row)
                    }} style={{height: '75px', cursor: 'pointer'}} src={getTapeIcon(formattedProperty)} alt={"Rendered SVG icon of the tape"}/>)
                    : (<div></div>)
            )
            : ((dataContainsFormatting && row?.attributes?.[column.mnemonic]?.searchable) || (!dataContainsFormatting && column?.searchable) ?
                    highlightText(row.item_id, formattedProperty)
                    : formattedProperty
            );
    };


    /**
     * function to render the table cell. It generates the cell content based on the column and row data.
     * @param row
     * @param {number} rowIndex
     * @param column
     * @returns {JSX.Element}
     */
    const dynamicTableCell = (row, rowIndex, column) => {
        // return empty cell if no column
        if (!column) return (
            <TableCell colSpan={2} align="centre">
                <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}></div>
            </TableCell>);

        return (
            <TableCell>
                <Typography sx={{color: "#FFFFFF", fontSize: 14, fontFamily: "Poppins", fontWeight: row.selected ? 700 : 400}}
                            className={(dataContainsFormatting && row?.attributes?.[column.mnemonic]?.searchable) || (!dataContainsFormatting && column?.searchable) ? ("restore-name") : ""}
                            key={rowIndex}
                            id={`restore-${column.mnemonic}-${rowIndex}`}>
                    {column.tooltip ?
                        (
                            <Tooltip title={dynamicTooltipConstruction(row, column?.tooltip)} slotProps={{tooltip: {className: "wideTooltip"},}}>
                                {dynamicContentForCellHelper(column, row)}
                            </Tooltip>
                        )
                        : dynamicContentForCellHelper(column, row)}
                </Typography>
            </TableCell>);
    }

    return (
        <>
            <div>  {/*className="auditTable"*/}
                {/*Table in which the components of tape list or files list will be rendered*/}
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableColumns ?
                                tableColumns.map((column) => [
                                    <TableCell className={classes.columnHeader}>
                                        <Typography sx={{marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700"}}>
                                            {column.name}
                                        </Typography>
                                    </TableCell>
                                ]) : (
                                    <TableCell colSpan={2} align="centre">
                                        <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
                                    </TableCell>
                                )}

                            {tableColumns && actionComponent ? (
                                <TableCell className={classes.columnHeader} align="centre">
                                    <Typography sx={{marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700", alignItems: "center", textAlign: "center"}}>
                                        Action
                                    </Typography>
                                </TableCell>
                            ) : ""}
                        </TableRow>
                    </TableHead>

                    <TableBody sx={{"& > *": {backgroundColor: "#3A3E3F", borderRadius: "8px",},}}>
                        {data.length > 0 ? data.map((row, rowIndex) => [
                                <TableRow key={rowIndex} sx={{"& > *": {border: "unset",},}}>
                                    {tableColumns ? (tableColumns.map((column) => dynamicTableCell(row, rowIndex, column))) : noDataCell()}

                                    {/*If tableColumns ensure and Add/Remove button is added at the end of each row */}
                                    {tableColumns && actionComponent ? actionComponent(row) : ""}
                                </TableRow>
                            ]) :
                            <TableRow>
                                <TableCell colSpan={2} align="centre">
                                    <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
                <TablePagination
                    showPagination={showPagination}
                    entriesCount={entriesCount}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChangeCallback={handlePageChangeCallback}
                />
            </div>
        </>);
};
export default DataTable;