export const TAPE_AUDIT_TABLE_COLUMNS = {
        "Standard tapes": [
            {name: "Tape Volser", mnemonic: "item_volser", ui_class: "str", searchable: true, tooltip: [{name: "Tape Volser", mnemonic: "item_volser", ui_class: "str"}, {name: "Tape type", mnemonic: "media_type", ui_class: "str"},]},
            {name: "Tape type", mnemonic: "media_type", ui_class: "str", special_render: "tape_photo"},
            {name: "Tape format", mnemonic: "backup_format", ui_class: "str"},
            {name: "Data Volume", mnemonic: "data_volume", ui_class: "data_size_iso_units", tooltip: [{name: "Bytes", mnemonic: "data_volume", ui_class: "data_size_bytes"},{name: "IEC std.", mnemonic: "data_volume", ui_class: "data_size_iec_units"}]},
            {name: "Retention Date", mnemonic: "retention_date", ui_class: "datetime"}, // 2031-08-20
            {name: "Created Date", mnemonic: "created", ui_class: "datetime"}, // 2021-08-20
            {name: "Comments", mnemonic: "metadata", ui_class: "str"},
            {name: "Status", mnemonic: "status", ui_class: "str"}
        ]
    }
;