import React, {useEffect, useState} from "react";
import Slider from "@mui/material/Slider";
import {PrimaryColor} from "../theme/theme";
import {Stack,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {formats} from "../../global";
import axios from "axios";
import {useFeatures} from "../../views/FeaturesProvider/featuresProvider";
import {getLoginUser} from "../../LocalStorageService";
import DataTable from "../../views/DataTable/DataTable";
import {TAPE_INGESTION_TABLE_COLUMNS} from "./ingestionTableCustomization";
import {TapeDetails} from "../../views/TapeDetails/tapeDetails";

const CustomSlider = styled(Slider)({
    color: "#D9D9D9",
    height: 15,
    "& .MuiSlider-track": {
        height: 15,
        border: "none",
        color: "#FFC540",
    },
    "& .MuiSlider-thumb": {
        height: 32,
        width: 32,
        backgroundColor: "#FFC540",
        border: "none",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: 1.2,
        fontSize: 16,
        background: "unset",
        padding: 0,
        width: 48,
        height: 48,
        color: "#3A3E3F",
        borderRadius: "50% 50% 50% 50%",
        backgroundColor: "#FFC540",
        transformOrigin: "bottom left",
        "&:before": {display: "none"},
        "&.MuiSlider-valueLabelOpen": {
            transform: "translateY(0%)",
        },
        "& > *": {},
    },
});

export const Ingestion = ({value, orderDetail}) => {
    const useStyles = makeStyles({
        rootMenuItem: {
            "&$selected": {
                backgroundColor: "red",
                "&:hover": {
                    backgroundColor: "green",
                    borderRadius: 12,
                    border: "1px solid var(--White, #FFF)",
                },
            },
            "&:hover": {
                backgroundColor: "transparent",
                borderRadius: 12,
                border: "1px solid var(--White, #FFF)",
            },
        },
        highlight: {
            background: "#FFC540",
            color: "#3A3E3F",
        },
        selectLabel: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderRadius: "8px",
                    borderColor: "#C7CCD0",
                },
                "&:hover fieldset": {
                    borderColor: "#C7CCD0",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#C7CCD0",
                },
            },
            "& .MuiOutlinedInput-input": {
                color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
                color: "rgba(255, 255, 255, 0.5)",
                "&.Mui-focused": {
                    color: "#FFC540",
                },
            },
            "& svg": {
                color: "#FFFFFF",
            },
        },
        checkedColr: {
            "&.Mui-checked": {
                color: "red",
            },
        },
    });
    const classes = useStyles();
    let stateMnemonicToName = {
        "audit_complete": "Ingestion Awaited",
        "ingestion_started": "Ingestion Started",
        "ingestion_complete": "Ingestion Complete",
    };
    const [allRestoreList, setAllRestoreList] = useState([]);

    const [restoreData, setRestoreData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const userDetails = getLoginUser();
    const [isAdmin, setIsAdmin] = useState(false);
    const features = useFeatures()
    const [totalFetched, setTotalFetched] = useState(false);
    const [entriesCount, setEntriesCount] = useState(0);
    const [selectedTapeId, setSelectedTapeId] = React.useState();
    const [openTapeDetails, setOpenTapeDetails] = React.useState(false);
    const [completedTap, setCompletedTap] = useState(0);

    const selectTapeForDetailing = (row) => {
        setSelectedTapeId(row?.item_id);
        setOpenTapeDetails(true);
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        getTapeData(isAdmin, newPage);
    };


    useEffect(() => {
        if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) {
            setIsAdmin(true);
            if (!totalFetched) {
                getTapeCount(true);
                setTotalFetched(true);
            }
        } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {
            setIsAdmin(false);
            if (!totalFetched) {
                getTapeCount(false);
                setTotalFetched(true);
            }
        }
    }, [features]);

    /**
     * Fetches from the SaaS API the count of tapes.
     * Side effects: updates the internal states totalPages (multiple of 10), entriesCount and calls getTapeData for page 1.
     * If nothing is returned, defaults to 0.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @returns {void}
     */
        // man_item_count
    const getTapeCount = (_isAdmin) => {
            let payload = {
                type: "man_item_count",
                request: {
                    cid: userDetails.user.arkbridge_customer_id,
                    job_id: orderDetail.tapeark_job_id,
                    type: "man_item_count"
                },
            };
            axios
                .post(formats, payload)
                .then((response) => {
                    if (response?.status == 200) {
                        let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
                        setTotalPages(Math.ceil(_count / 10));
                        setEntriesCount(_count);
                        getTapeData(_isAdmin, 1);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log("getTapeCount error", userDetails.user);
                });
        };

    /**
     * Fetches from the SaaS API the tapes' data. It fetches the data for max 10 tapes of a specific page.
     * Side effects: updates the internal states allRestoreList, restoreData and calls mapAddedRows for the first max 10 items returned.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {number} pageNumber - Page number
     */
    const getTapeData = (_isAdmin, pageNumber) => {
        let payload = {
            type: "man_item_list",
            request: {
                cid: userDetails.user.arkbridge_customer_id,
                job_id: orderDetail.tapeark_job_id,
                type: "man_item_list",
                page_number: pageNumber - 1,
                page_size: 10
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    let dynamicArray;
                    let dynamicObject;
                    dynamicObject = Object.values(response.data.result)
                    dynamicObject.forEach(i => dynamicArray = i.data)
                    setRestoreData(dynamicArray.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("getTapeData error", userDetails.user);
            });
    };


    // React.useEffect(() => {
    //     // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
    //     let payload = {
    //         type: "man_item_list",
    //         request: {
    //             job_id: orderDetail.tapeark_job_id
    //         },
    //     };
    //     axios
    //         .post(formats, payload)
    //         .then((response) => {
    //             if (response?.status == 200) {
    //                 let dynamicArray = ''
    //                 const dynamicObject = Object.values(response.data.result)
    //                 dynamicObject.forEach(i => {
    //                         if (Array.isArray(i.data) && i.data.length) {
    //                             dynamicArray = i.data
    //                         }
    //                     }
    //                 )
    //                 setAllRestoreList(
    //                     dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id))
    //                 );
    //                 setRestoreData(
    //                     dynamicArray
    //                         .filter((i) => i.job_id == (orderDetail.tapeark_job_id))
    //                         .slice(0, 10)
    //                 );
    //                 setTotalPages(
    //                     Math.ceil(
    //                         dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id))
    //                             .length / 10
    //                     )
    //                 );
    //                 setTapeCount(
    //                     dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id)).length
    //                 );
    //                 setCompletedTap(
    //                     dynamicArray
    //                         .filter((f) => f.job_id == (orderDetail.tapeark_job_id))
    //                         .filter(
    //                             (i) =>
    //                                 i.status_id == 3 ||
    //                                 i.status_id == 4 ||
    //                                 i.status_id == 11
    //                         ).length
    //                 );
    //             }
    //         })
    //
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //
    //
    // }, []);

    const ingestionMainTitle = (
        <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
                sx={{
                    flex: "1 1 100%",
                    pt: 2,
                    textAlign: "left",
                    px: 2,
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "#FFFFFF",
                }}
                id="state-title"
                component="div"
            >
                {/* Changes here to show appropriate status message */}
                {/* {(value === 100) ? 'Ingestion Completed' : 'Ingestion Started'} */}
                {orderDetail.status ? (
                        stateMnemonicToName[String(orderDetail.status).toLowerCase()] ?
                            stateMnemonicToName[String(orderDetail.status).toLowerCase()]
                            : String(orderDetail.status))
                    : orderDetail.status}
            </Typography>
        </Box>);

    const ingestionSubTitle = (
        <Typography
            sx={{
                flex: "1 1 100%",
                pt: 2,
                textAlign: "left",
                px: 2,
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                color: "#FFFFFF",
            }}
            id="state-text"
            component="div"
        >
            Your tapes are being ingested. You will be notified once it is
            completed.
        </Typography>);

    const getSliderConstruct = () => {
        return (
            <Box style={{position: "relative"}}>
                <CustomSlider
                    disabled
                    value={isNaN(((completedTap / entriesCount) * 100).toFixed(2)) ? 0 : ((completedTap / entriesCount) * 100).toFixed(2)}
                    valueLabelDisplay="on"
                    valueLabelFormat={(v) => `${v}%`}
                />
                <Box style={{
                    width: "91px",
                    height: "95px",
                    borderRadius: "8px",
                    border: "1px solid #FFC540",
                    position: "absolute",
                    top: "calc(100% + 8px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: PrimaryColor,
                    zIndex: 1,
                    marginLeft: `calc(${isNaN(((completedTap / entriesCount) * 100).toFixed(2)) ? 0 : ((completedTap / entriesCount) * 100).toFixed(2)}% - 40px)`
                }}>
                    <Stack direction={"column"}>
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "22px",
                                lineHeight: "33px",
                                color: "#FFC540",
                            }}
                        >
                            {completedTap}/{entriesCount}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "27px",
                                color: "#FFFFFF",
                            }}
                        >
                            Tapes Ingested
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <TapeDetails open={openTapeDetails} closePopup={() => {
                setOpenTapeDetails(false)
            }} tapeId={selectedTapeId}></TapeDetails>

            <Box padding={6} minHeight={"100vh"}>
                {ingestionMainTitle}
                {/*{ingestionSubTitle}*/}
                {getSliderConstruct()}

                <div className="auditTable">
                    <DataTable
                        data={restoreData.length > 0 ? restoreData : []}
                        tableColumns={TAPE_INGESTION_TABLE_COLUMNS["Standard tapes"]}
                        dataContainsFormatting={false}
                        componentsClickCallBackMap={{"tape_photo": selectTapeForDetailing}}
                        classes={classes}
                        showPagination={true}
                        entriesCount={entriesCount}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChangeCallback={handlePageChange}
                    />
                </div>
            </Box>
        </>
    );
};
