import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {stepConnectorClasses,} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Tab from "@mui/material/Tab";
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";
import React, {useCallback, useEffect} from "react";
import Grid from "@mui/material/Grid";
import StepContent from "@mui/material/StepContent";
import CheckIcon from "@mui/icons-material/Check";
import {Requests} from "../../component/request/request";
import {Audit} from "../../component/audit/audit";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Ingestion} from "../../component/ingestion/ingestion";
import {getApiUrl} from "../../global";

import {Details} from "../../component/details/details";
import {StyledButtonOutlined} from "../Admin/AccountCreation/accountCreation";
import {Messages} from "../../component/messages/messages";
import {getFormattedProperty} from "../../utils/utils";

export const OrderTracking = () => {
    const staticState = [{
        key: "ingestion_complete", value: "Ingestion Complete", width: "100%",
    }, {
        key: "ingestion_started", value: "Ingestion Started", width: "100%",
    }, {
        key: "audit_complete", value: "Audit Complete", width: "90%",
    }, {
        key: "audit_started", value: "Audit Started", width: "80%",
    }, {
        key: "verified_and_completed", value: "Verified & Completed", width: "20%",
    }, {
        key: "out_for_delivery", value: "Out For Delivery", width: "20%",
    }, {
        key: "shipment_collected", value: "Shipment Collected", width: "20%",
    }, {
        key: "courier_en_route", value: "Courier En route", width: "20%",
    }, {
        key: "preparing_for_shipment", value: "Preparing For Shipment", width: "20%",
    },];
    const [hStepInFocus, setHStepInFocus] = React.useState(1);
    const params = useParams();
    const [orderDetail, setOrderDetail] = React.useState({});

    useEffect(() => {
        console.log("hStepInFocus changed", hStepInFocus);
    }, [hStepInFocus]);

    useEffect(() => {
        getOrderDetail();
    }, []);

    /**
     * Function used:
     * - to retrieve order details for the given order id
     * - ensure that all the order states are present in the order details object (where information is missing, blank values are added)
     * - stores the order details in the state variable orderDetail.
     * Side effects:
     * - updates the state variable orderDetail
     * - updates the state variable hStepInFocus – used to determine the active step in the horizontal stepper
     */
    const getOrderDetail = () => {
        let url = getApiUrl();
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + params.orderId;

        axios
            .get(apiUrl)
            .then((data) => {
                const key = "status";
                data.data["_order_states"] = JSON.parse(JSON.stringify(data.data.order_states));
                data.data.order_states = [...new Map(data.data.order_states.map((item) => [item[key], item])).values(),]; // Removes duplicates from data.data.order_states. If 2 entries are duplicate, it will keep the last one.
                // if (data.data.order_states.length < staticState.length) {
                staticState.forEach((stateDescription) => {
                    let orderState = data.data._order_states.find((elements) => String(elements.state) === stateDescription.key);
                    if (orderState?.state) {
                        stateDescription["timestamp"] = orderState.timestamp;
                        stateDescription["message"] = orderState.message;
                        stateDescription["status"] = String(orderState.state);
                    } else {
                        stateDescription["timestamp"] = "";
                        stateDescription["message"] = "";
                        stateDescription["status"] = String(stateDescription.key);
                    }
                });
                data.data.order_states = staticState;
                // }
                setOrderDetail(data.data);
                // setTimeout(() => {
                setHStepInFocus(getHActiveStep(data.data));
                // }, 100);
            })
            .catch((error) => console.log(error));
    };

    const statusToStep = {
        "verified_and_completed": 2,
        "audit_started": 2,
        "audit_complete": 3,
        "ingestion_started": 3,
        "ingestion_complete": 4
    };

    function getHActiveStep(_orderDetail) {
        return Object.keys(statusToStep).includes(_orderDetail.status.toLowerCase()) ? statusToStep[_orderDetail.status.toLowerCase()] : 1;
    }

    const [tabInFocus, setTabInFocus] = React.useState("1");
    const handleTabChange = (event, newTabValue) => {
        setTabInFocus(newTabValue);
    };

    const ColorlibConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 40,
        }, [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: "background(180deg, #EEAB10 0%, #FFC133 100%)",
            },
        }, [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
            },
        }, [`& .${stepConnectorClasses.line}`]: {
            height: 1, border: 0, backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EAEAEA", borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled("div")(({theme, ownerState}) => ({
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "", zIndex: 1, color: "#fff", width: 35, height: 35, display: "flex", borderRadius: "50%", justifyContent: "center", alignItems: "center", ...(ownerState.active && {
            // backgroundImage:
            //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        }), ...(ownerState.completed && {
            background: "#ffc54021", color: "#FFC540;", // opacity: 0.5,
        }),
    }));


    /**
     * Function to determine the step that is currently completed.
     * @returns {number}
     */
    const getCompletedStepIndex = useCallback(() => {
        return statusToStep[orderDetail?.status?.toLowerCase()] ? statusToStep[orderDetail.status.toLowerCase()] : 1;
    }, [orderDetail]);


    /**
     * React component used to display the horizontal evolution of the order status through all the phases.
     * @returns {{}} - A map with corresponding React components for each step of the horizontal stepper.
     */
    const getStepsComponents = useCallback(() => {
        const completedStep = getCompletedStepIndex();
        const textToStep = {
            1: (<div className="progressBarText progressBarText-orderPlaced"> Order <br/> Placed </div>),
            2: (<div className="progressBarText progressBarText-shipping"> Shipping </div>),
            3: (<div className="progressBarText progressBarText-audit">Audit</div>),
            4: (<div className="progressBarText progressBarText-ingestion"> Ingestion </div>)
        };

        let horizontalStepper = {};

        for (let i = 1; i <= 4; i++) {
            horizontalStepper[i] = (
                <>
                    {completedStep < i ? (<div>{i}</div>) : (<div className="horizontalStepperIcon_Completed"><CheckIcon></CheckIcon></div>)}
                    {textToStep[i]}
                </>);
        }
        return horizontalStepper;
    }, [getCompletedStepIndex]);

    function ColorlibStepIcon(props) {
        const stepsComponentes = getStepsComponents();
        const {active, completed, className} = props;
        return (
            <ColorlibStepIconRoot
                ownerState={{completed, active}}
                className={className}>
                {stepsComponentes[String(props.icon)]}
            </ColorlibStepIconRoot>);
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool, className: PropTypes.string, /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool, /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    /**
     * Function to determine and populate the status of each step in the horizontal stepper (from completed to active and pending).
     * @returns {[{label: string, status: string},{label: string, status: string},{label: string, status: string},{label: string, status: string}]}
     */
    const getStepsStatusCollection = useCallback(() => {
        const activeStep = getCompletedStepIndex() + 1;
        const labelToStep = {"step-orderPlaced": 1, "step-shipping": 2, "step-audit": 3, "step-ingestion": 4};

        let horizontalStepperActiveStep = [
            {label: "step-orderPlaced", status: "horizontalStepper-completed"},
            {label: "step-shipping", status: "horizontalStepper-active"},
            {label: "step-audit", status: "horizontalStepper-pending"},
            {label: "step-ingestion", status: "horizontalStepper-pending"}];

        horizontalStepperActiveStep.forEach(labelDetail => {
            if (labelToStep[labelDetail.label] > activeStep) {
                labelDetail.status = "horizontalStepper-pending";
            } else if (labelToStep[labelDetail.label] === activeStep) {
                labelDetail.status = "horizontalStepper-active";
            } else {
                labelDetail.status = "horizontalStepper-completed";
            }
        });
        return horizontalStepperActiveStep;
    }, [getCompletedStepIndex]);

    /**
     * Function to update the active step in the horizontal stepper.
     * @type {function(*): function(): void}
     */
    const updateHStepInFocus = useCallback((step) => () => setHStepInFocus(step), []);

    /**
     * Function to get the stepper section component.
     * @returns {Element}
     */
    const getStepperSectionComponent = useCallback(() => {
        const clickableStepLabel = index => {
            return (
                <StepLabel StepIconComponent={ColorlibStepIcon}
                           onClick={updateHStepInFocus(index + 1)} // compensate for the 0-based index and 1-based step
                           style={{cursor: "pointer"}}>
                </StepLabel>);
        };

        const nonClickableStepLabel = () => {
            return (
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                </StepLabel>);
        };

        return (
            <div className="stepper-section" style={{marginLeft: width > 2000 ? "-11%" : "-7.5%"}}>
                <Stack sx={{width: "100%"}}>
                    <Grid item>
                        <Stepper
                            alternativeLabel
                            activeStep={1}
                            connector={<ColorlibConnector/>}>
                            {getStepsStatusCollection().map((step, index) => [
                                <Step key={step.label} className={step.label + " " + step.status}>
                                    {getCompletedStepIndex() >= (index + 1) && index > 0 ? clickableStepLabel(index) : nonClickableStepLabel()}
                                </Step>])}
                        </Stepper>
                    </Grid>
                </Stack>
            </div>);
    }, [getStepsStatusCollection, getCompletedStepIndex]);

    /**
     * Function to render the content of the Tracking tab panel. It renders only if the tab in focus is the Tracking tab.
     * @type {(function(): *)|*}
     */
    const trackingTabPanelContent = useCallback(() => {
        for (const tabKey in tabs) {
            if (tabInFocus === tabKey && tabs[tabKey].label !== "Tracking") {
                return <></>;
            }
        }

        let shippingDetails = <VerticalLinearStepper orderDetail={orderDetail}/>;

        const hStepToComponent = {
            2: shippingDetails,
            3: <Audit value={100} orderDetail={orderDetail}/>,
            4: <Ingestion orderDetail={orderDetail}/>
        };

        return (
            <Grid container direction="column">
                {getStepperSectionComponent()}
                <Grid item>
                    {hStepToComponent[hStepInFocus]}
                </Grid>
            </Grid>
        );
    }, [tabInFocus, hStepInFocus, orderDetail]);

    /**
     * Function to render the content of the Details tab panel. It renders only if the tab in focus is the Details tab.
     * @type {(function(): *)|*}
     */
    const detailsTabPanelContent = useCallback(() => {
        for (const tabKey in tabs) {
            if (tabInFocus === tabKey && tabs[tabKey].label !== "Details") {
                return <></>;
            }
        }

        return <Details orderDetail={orderDetail}/>;
    }, [tabInFocus, orderDetail]);

    /**
     * Function to render the content of the Uploads tab panel. It renders only if the tab in focus is the Uploads tab.
     * @type {(function(): *)|*}
     */
    const uploadsTabPanelContent = useCallback(() => {
        for (const tabKey in tabs) {
            if (tabInFocus === tabKey && tabs[tabKey].label !== "Uploads") {
                return <></>;
            }
        }
        return <Requests/>;
    }, [tabInFocus, orderDetail]);

    /**
     * Function to render the content of the Messages tab panel. It renders only if the tab in focus is the Messages tab.
     * @type {(function(): *)|*}
     */
    const messagesTabPanelContent = useCallback(() => {
        for (const tabKey in tabs) {
            if (tabInFocus === tabKey && tabs[tabKey].label !== "Messages") {
                return <></>;
            }
        }
        return <Messages/>;
    }, [tabInFocus]);

    const tabs = {
        1: {label: "Tracking", content: trackingTabPanelContent},
        2: {label: "Details", content: detailsTabPanelContent},
        3: {label: "Uploads", content: uploadsTabPanelContent},
        // 4: {label: "Messages", content: messagesTabPanelContent, sx: {paddingTop: "0px", paddingBottom: "0px"}}
    };

    /**
     * Function to get the tab list component.
     * @returns {Element}
     */
    const getTabList = useCallback(() => {
        const tabSX = {
            "&.MuiTab-root": {
                color: "#5F5F5F",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 700,
                textTransform: "capitalize",
            }
        };

        return (
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <TabList
                    sx={{
                        "& .MuiTabs-scroller": {
                            background: "linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)",
                        }, "& .MuiTabs-indicator": {
                            background: "#3A3E3F", mb: "10px",
                        },
                    }}
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    className="tab-bg">
                    {Object.keys(tabs).map((key) => [
                        <Tab
                            sx={tabSX}
                            label={tabs[key].label}
                            value={key}
                        />
                    ])}
                </TabList>
            </Box>
        );
    }, []);

    /**
     * Function to get the tab panels component.
     * @returns {React.JSX.Element[][]}
     */
    const getTabPanels = useCallback(() => {
        return Object.keys(tabs).map((key) => [
            <TabPanel sx={tabs[key].sx ? tabs[key].sx : {}} value={key}>
                {tabs[key].content()}
            </TabPanel>
        ]);
    }, [trackingTabPanelContent, detailsTabPanelContent, uploadsTabPanelContent, messagesTabPanelContent]);

    const getBreadcrumb = useCallback(() => {
        return (
            <div className="breadcrumb">
                <img src="/assets/img/cart.svg" alt=""/>
                <img src="/assets/img/arrow_left.svg" alt=""/>
                <Link to="/CustomerOrders" passHref style={{textDecoration: "none"}}>
                    <small className="grey-color">Your Orders</small>
                </Link>
                <img src="/assets/img/arrow_left.svg" alt=""/>
                <small className="grey-color">{orderDetail["order_number"]}</small>
            </div>
        );
    }, [orderDetail]);

    let width = window.innerWidth;
    return (
        <>
            <Box p={4}>
                {getBreadcrumb()}
                <div className="heading">
                    <h1>{orderDetail["order_number"]}</h1>
                </div>
                <Box mb={2}>
                    <hr/>
                </Box>
                <div className="tab-section">
                    <TabContext value={tabInFocus}>
                        {getTabList()}
                        {getTabPanels()}
                    </TabContext>
                </div>
            </Box>
        </>);
};

function getAddressDetails(shipments, currentState) {
    let _shipments = JSON.parse(JSON.stringify(shipments));
    _shipments = _shipments.filter((element) => currentState === element.status);
    if (_shipments?.length) {
        return _shipments.map((element) => {
            return {
                ...element, // name: element.contact_name,
                location: element.shipping_address.address_line_1,
                city: element.shipping_address.state,
                pinCode: element.shipping_address.postcode,
                status: "50",
                name: element?.first_name + " " + element?.last_name
            };
        });
    } else {
        return undefined;
    }
}

const stateMnemonicToName = {
    "preparing_for_shipment": "Preparing For Shipment",
    "courier_en_route": "Courier En route",
    "shipment_collected": "Shipment Collected",
    "out_for_delivery": "Out For Delivery",
    "verified_and_completed": "Verified & Completed",
    "audit_started": "Audit Started",
    "audit_complete": "Audit Complete",
    "ingestion_started": "Ingestion Started",
    "ingestion_complete": "Ingestion Complete"
};

function getDetailedOrderSteps(orderDetail) {
    if (orderDetail?._order_states.length) {
        let currentIndex = orderDetail._order_states
            .map((orderState) => orderState.status.toLowerCase())
            .indexOf(orderDetail.status.toLowerCase());
        return orderDetail._order_states.map((orderState, index) => {
            return {
                ...orderState,
                id: index,
                label: stateMnemonicToName[orderState.status] ? stateMnemonicToName[orderState.status] : orderState.status,
                description: orderState.message,
                date: orderState?.timestamp && getFormattedProperty(orderState, "timestamp", "datetime") ? getFormattedProperty(orderState, "timestamp", "datetime") : orderState?.timestamp,
                status: currentIndex > index || orderDetail.status.toLowerCase() === "verified_and_completed" ? "completed" : currentIndex === index ? "pending" : "disabled",
                deliveryLocations: orderDetail?.shipments?.length ? getAddressDetails(orderDetail?.shipments, orderState.status) : undefined,
                isVisible: !["audit_started", "audit_complete", "ingestion_started", "ingestion_complete"].includes(orderState.status)
            };
        });
    } else {
        return [];
    }
}

export default function VerticalLinearStepper({orderDetail}) {
    if (orderDetail?.["order_number"]) {
        orderDetail["_order_states"] = JSON.parse(JSON.stringify(orderDetail.order_states));
        orderDetail["_order_states"].reverse();
    }
    let detailedOrderSteps = orderDetail?.["order_number"] ? getDetailedOrderSteps(orderDetail) : [];
    const [activeStep, setActiveStep] = React.useState(1);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const goToStep = useCallback(index => setActiveStep(index), []);

    const openTrackingPage = (location) => {
        const linksMap = {
            dhl: "https://www.dhl.com/in-en/home/tracking.html",
            fedex: "https://www.fedex.com/en-in/tracking.html",
            ups: "https://www.ups.com/track?loc=en_IN&requester=ST/",
            mnx: "https://www.mnx.com/tracking/"
        };

        const linkToUse = location.tracking_link ? location.tracking_link : linksMap[String(location.courier_partner)];
        window.open(linkToUse, "_blank");
    };

    const getStepLabel = useCallback((step, index) => {
        const iconsMap = {
            completed: () => (<div className="verticalStepperIcon_Completed"><CheckIcon></CheckIcon></div>),
            pending: () => (<div className="verticalStepperIcon_Inprogress"></div>),
            disabled: () => (<div className="verticalStepperIcon_Disabled"></div>)
        };

        const stepLabelInternals = () => {
            return (
                <>
                <span className={step.status === "disabled" ? "verticalStepLabelDisabled" : "verticalStepLabel"}>
                    {step.label}
                  </span>
                    <br>
                    </br>
                    <small className="dateLabel">{step.date}</small>
                </>
            );
        };

        if (step.status === "disabled") {
            return (
                <StepLabel StepIconComponent={iconsMap[step.status]}>
                    {stepLabelInternals()}
                </StepLabel>
            );
        } else {
            return (
                // <StepLabel StepIconComponent={iconsMap[step.status]} onClick={() => goToStep(index)} style={{cursor: "pointer"}}> // Uncomment this line to make the step clickable
                <StepLabel StepIconComponent={iconsMap[step.status]}>
                    {stepLabelInternals()}
                </StepLabel>
            );
        }


    }, []);

    const getStepContent = useCallback((step) => {
        const getLocationComponent = (location, indexOfLocation) => {
            return (
                <div key={location.name}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <div className="locationIndex">
                                <div className="locationIndexText">{indexOfLocation + 1}</div>
                            </div>
                        </Grid>
                        <Grid item xs={7}>
                            <div>
                                <span className="deliveryLocationName">{location.name}</span>
                                <br/>
                                <small className="deliveryLocationArea">{location.location}</small>
                                <br/>
                                <small className="deliveryLocationCity">{location.city}</small>&nbsp;<small className="deliveryLocationPin">{location.pinCode}</small>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <StyledButtonOutlined
                                    variant="outlined"
                                    className="deliveryLocationButton"
                                    style={{textTransform: "none"}}
                                    onClick={() => openTrackingPage(location)}
                                >
                                    Track
                                </StyledButtonOutlined>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="deliveryLocationStatus"></div>
                        </Grid>
                    </Grid>
                </div>
            );
        };

        return (
            <StepContent>
                <span className="stepDescription">{step.description}</span>
                {step && step.deliveryLocations && step?.deliveryLocations?.length && (
                    <Box sx={{mb: 2}}>
                        <div className="deliveryLocationsContainer">
                            <h4 className="deliveryLocationsHeadingText">
                                Pickup Locations
                            </h4>
                            <Stack spacing={2} divider={<Divider flexItem/>}>
                                {step.deliveryLocations.map((location, indexOfLocation) => getLocationComponent(location, indexOfLocation))}
                            </Stack>
                        </div>
                    </Box>)}
            </StepContent>
        );
    }, []);

    const getStepComponent = useCallback((step, index) => {
        return (
            <Step
                active
                expanded
                key={step.id}
                className={step.status !== "disabled" ? "activeVerticalBar" : "disabledVerticalBar"}
            >
                {getStepLabel(step, index)}
                {getStepContent(step)}
            </Step>
        );
    }, []);

    return (<Box sx={{maxWidth: 400}}>
        <Stepper activeStep={1} orientation="vertical" sx={{m: 8}}>
            {detailedOrderSteps.map((step, index) => step.isVisible && getStepComponent(step, index))}
        </Stepper>
    </Box>);
}
