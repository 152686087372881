export const TAPE_RESTORE_TABLE_COLUMNS = {
    "Standard tapes": [
        {name: "Tape Volser", mnemonic: "item_volser", ui_class: "str", searchable: true, tooltip: [{name: "Tape Volser", mnemonic: "item_volser", ui_class: "str"}, {name: "Tape type", mnemonic: "media_type", ui_class: "str"}]},
        // {name: "Tape type", mnemonic: "media_type", ui_class: "str"},
        {name: "Tape type", mnemonic: "media_type", ui_class: "str", special_render: "tape_photo"},
        {name: "Tape format", mnemonic: "backup_format", ui_class: "str"},
        {name: "Data Volume", mnemonic: "data_volume", ui_class: "data_size_iso_units", tooltip: [{name: "Bytes", mnemonic: "data_volume", ui_class: "data_size_bytes"},{name: "IEC std.", mnemonic: "data_volume", ui_class: "data_size_iec_units"}]},
        {name: "Retention Date", mnemonic: "retention_date", ui_class: "datetime"}, // 2031-08-20
        {name: "Created Date", mnemonic: "created", ui_class: "datetime"}, // 2021-08-20
        {name: "Comments", mnemonic: "metadata", ui_class: "str"},
        // {name: "Tape Photograph", mnemonic: "media_type", ui_class: "str", special_render: "tape_photo"}]
    ]
};

export const FILES_RESTORE_TABLE_COLUMNS = {
    "Standard files view": [
        {name: "File Name", mnemonic: "name", tooltip: [{mnemonic: "path", ui_class: "str"},]},
        {name: "Tape Volser", mnemonic: "volsers"},
        {name: "File type", mnemonic: "file_type"},
        {name: "File group", mnemonic: "file_groups"},
        {name: "Data Volume", mnemonic: "size", tooltip: [{name: "Bytes", mnemonic: "size", ui_class: "data_size_bytes"},{name: "IEC std.", mnemonic: "size", ui_class: "data_size_iec_units"}]},
        {name: "Retention Date", mnemonic: "backed_up"}, // 06/May/2018
        {name: "Created Date", mnemonic: "created"} // 31/Dec/2024
    ],
    "Seismic SegD view": [
        {name: "File Name", mnemonic: "name", tooltip: [{name: "File path", mnemonic: "path", ui_class: "str"}, {name: "Tape Volser", mnemonic: "volsers", ui_class: "str"},]},
        {name: "Tape Volser", mnemonic: "volsers"},
        {name: "Created Date", mnemonic: "SEGDSTARTSTAMP", tooltip: [{name:"Start timestamp",mnemonic: "SEGDSTARTSTAMP", ui_class: "datetime"},{name:"End timestamp",mnemonic: "SEGDENDSTAMP", ui_class: "datetime"}]},
        {name: "Survey", mnemonic: "SEGDSURVEY"},
        {name: "Contractor", mnemonic: "SEGDCONTRACTOR"},
        {name: "Client Name", mnemonic: "SEGDCLIENT"},
        {name: "Format", mnemonic: "SEGDFORMAT"},
        {name: "File Number", mnemonic: "SEGDSTARTFNUM", tooltip: [{name:"Start file number",mnemonic: "SEGDSTARTFNUM", ui_class: "int"},{name:"End file number",mnemonic: "SEGDENDFNUM", ui_class: "int"}]},
    ],
    "Seismic SegY view": [
        {name: "File Name", mnemonic: "name", tooltip: [{mnemonic: "SEGYEBCDICALL", ui_class: "str"}]},
        {name: "Tape Volser", mnemonic: "volsers"},
        {name: "Survey", mnemonic: "SEGYSURVEY"},
        {name: "Created Date", mnemonic: "backed_up"},
        {name: "Data Volume", mnemonic: "size", tooltip: [{mnemonic: "size", ui_class: "data_size_bytes"},]},
        {name: "Sample Interval", mnemonic: "SEGYSMPLINTRVL"},
        {name: "Samples per trace", mnemonic: "SEGYSMPLPERTRACE"},
        {name: "Format", mnemonic: "SEGYDATASMPLFMTCODETXT"},
    ]
};

export const SEISMIC_CUSTOMER_ARKBRIDGE_IDS = [162, 78];