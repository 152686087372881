import moment from 'moment';

/**
 * Function to check if an object is an array of strings.
 * @param obj
 * @returns {boolean}
 */
export function isArrayOfStrings(obj) {
    return Array.isArray(obj) && obj.every(item => typeof item === "string");
}

/**
 * Function to check if an object is a string.
 * @param dataToFormat
 * @returns {boolean}
 */
export function isString(dataToFormat) {
    return typeof dataToFormat === "string";
}

/**
 * Function to format bytes to the largest unit possible.
 * @param bytes - Number of bytes to be formatted. If 0 or not a number, returns 0.
 * @param decimals - Number of decimal places to be displayed. If lower than 0, defaults to 0.
 * @param useBinaryUnits - If true, uses binary units (1024 bytes = 1 KiB). If false, uses decimal units (1000 bytes = 1 KB).
 * @returns {string} - Formatted string with the largest unit possible.
 */
const formatBytes = (bytes, decimals = 0, useBinaryUnits = true) => {
    if (!+bytes) return '0'

    const k = useBinaryUnits ? 1024 : 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = useBinaryUnits ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Function to format a string or a list of strings into a single string.
 * @param {string | string[]} dataToFormat - Data to be formatted.
 * @returns {string} - Formatted string.
 */
const formatString = (dataToFormat) => {
    if (isString(dataToFormat)) {
        return dataToFormat;
    } else if (isArrayOfStrings(dataToFormat)) {
        return dataToFormat.join(", ");
    } else {
        return undefined;
    }
}

/**
 * Function to format a date string into a 'DD/MMM/YYYY' string.
 * @param dataToFormat - Date string to be formatted.
 * @returns {string} - Formatted date string.
 */
const formatDateTime = (dataToFormat) => {
    // possible formats:
    // 2025-01-15 17:41:58
    if (typeof dataToFormat !== "string") return undefined;

    const dateFormats = [
        'DD/MMM/YYYY',
        'D/MMM/YYYY',
        'DD/MM/YYYY',
        'D/MM/YYYY',
        'DD-MM-YYYY',
        'D-MM-YYYY',
        'YYYY-MM-DD',
        'YYYY-M-D'
    ];
    const dateTimeFormats = dateFormats.map(format => `${format} HH:mm:ss`).concat(dateFormats.map(format => `${format} H:m:s`));
    const dateTimeOffsetFormats = dateFormats.map(format => `${format} HH:mm:ssZ`).concat(dateFormats.map(format => `${format} H:m:sZ`));
    // Additional ISO formats with a 'T' separator and optional fractional seconds. 2025-01-08T07:30:50.215314Z
    const isoFormats = [
        "YYYY-MM-DD[T]HH:mm:ssZ",
        "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
        "YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ"
    ];
    const allFormats = [...dateFormats, ...dateTimeFormats, ...dateTimeOffsetFormats, ...isoFormats];

    const parsedDate = moment(dataToFormat, allFormats, true);

    if (!parsedDate.isValid()) return undefined;
    return parsedDate.format('DD/MMM/YYYY');
}

/**
 * Function to format an integer as a string.
 * @param {number} dataToFormat
 * @returns {string} - Formatted integer.
 */
const formatInt = (dataToFormat) => {
    return `${dataToFormat}`;
}

/**
 * Function to format an integer as a string with grouping of thousands.
 * @param dataToFormat
 * @returns {string}
 */
const formatGroupedInt = (dataToFormat) => {
    return new Intl.NumberFormat('en-US', {useGrouping: true})
        .format(dataToFormat)
        .replace(/,/g, ' ');
}

/**
 * Method to parse the data from the API to the internal format.
 * @param data
 * @param {string} ui_class_to_format_to - The ui_class that sets the way UI will format the data. str, data_size, date
 * @returns {string} - Parsed data as a string.
 */
const formatData = (data, ui_class_to_format_to) => {
    if (!data) return "";
    if (!ui_class_to_format_to) return "";

    const functionMap = {
        "str": formatString,
        "filename": formatString,
        "filepath": formatString,
        "list": formatString,
        "data_size": data_size => formatBytes(data_size, 2, false),
        "data_size_iec_units": formatBytes,
        "data_size_iso_units": data_size => formatBytes(data_size, 2, false),
        "data_size_bytes": data_size => `${formatGroupedInt(data_size)} bytes`,
        "int": formatInt,
        "datetime": formatDateTime,
        "date": formatDateTime,
        "duration_ms": duration => `${formatGroupedInt(duration)} μs`
    };
    return Object.keys(functionMap).includes(ui_class_to_format_to) ? functionMap[ui_class_to_format_to](data) : "";
}

/**
 * For a given element, it returns the formatted property (found directly as a property or in "attributes") based on the column type. It caches the formatted property in the element object.
 * @param element
 * @param {string | null | undefined} property
 * @param {string} columnType
 * @returns {string}
 */
export const getFormattedProperty = (element, property, columnType) => {
    if (!element) return "";
    if (!columnType) return "";
    if (!property) return formatData(element, columnType);

    const formattedPropertyName = `__${property}_formatted_as_${columnType}`;
    if (!element[formattedPropertyName]) {
        const rawData = element?.attributes && element.attributes?.[property]?.value ? element.attributes[property].value : element[property];
        element[formattedPropertyName] = formatData(rawData, columnType);
    }
    return element[formattedPropertyName];
};