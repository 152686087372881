import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import {makeStyles, styled} from "@mui/styles";

import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {alpha} from "@mui/material/styles";
import {formats, getApiUrl} from "../../../../../global";
import {Box, Button, Checkbox, Grid, IconButton, Slider, Stack, Typography} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {ButtonColor, PrimaryColor} from "../../../../../component/theme/theme";
import {TextareaAutosize as BaseTextareaAutosize} from "@mui/base/TextareaAutosize";
import TextField from "@mui/material/TextField";
import axios from "axios";
//import { env } from '../../../../../env';
import {useParams} from "react-router";
import {StyledButtonContained, StyledButtonOutlined,} from "../../../AccountCreation/accountCreation";
import CommonTable from "./CommonTable";
import {getLoginUser} from "../../../../../LocalStorageService";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#3A3E3F",
    border: "none",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    width: 998,
};
const CustomSlider = styled(Slider)({
    color: "#D9D9D9",
    height: 15,
    "& .MuiSlider-track": {
        height: 15,
        border: "none",
        color: "#FFC540",
    },
    "& .MuiSlider-thumb": {
        height: 32,
        width: 32,
        backgroundColor: "#FFC540",
        border: "none",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: 1.2,
        fontSize: 16,
        background: "unset",
        padding: 0,
        width: 48,
        height: 48,
        color: "#3A3E3F",
        borderRadius: "50% 50% 50% 50%",
        backgroundColor: "#FFC540",
        transformOrigin: "bottom left",
        "&:before": {display: "none"},
        "&.MuiSlider-valueLabelOpen": {
            transform: "translateY(0%)",
        },
        "& > *": {},
    },
});


const columns = [
    {field: "id", headerName: "ID", width: 70},
    {field: "firstName", headerName: "First name", width: 130},
    {field: "lastName", headerName: "Last name", width: 130},
    {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 90,
    },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
];

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: "#4E5152 !important",
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#fff !important",
    boxShadow: "none !important",
}));

export const AdminAuditManagement = (props) => {
    const params = useParams();
    const [orderId, setOrderId] = React.useState(params.orderId);
    const [auditId, setAuditId] = React.useState();
    const [executiveSummary, setExecutiveSummary] = React.useState();
    const [recommendations, setRecommendations] = React.useState();
    const [findings, setFindings] = React.useState();


    const FileInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    const [files, setFiles] = React.useState([]);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [isPreview, setIsPreview] = React.useState(false);
    const handleOpen = (_isPreview) => {
        setIsPreview(_isPreview);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const Textarea = styled(BaseTextareaAutosize)(
        ({theme}) => `
        width: 900px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: red;
        background: purple;
      
      `
    );
    const handleFileSelect = (e) => {
        if (e.target.files) {
            let selectedFiles = Array.from(e.target.files);
            // let _files = files;
            // _files.push(selectedFiles);
            // setFiles(_files)
            setFiles([...files, selectedFiles]);
            // uploadFile(selectedFiles, 'signed_contract');
        }
    };
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    async function uploadFile(file, uuid) {
        const result = await toBase64(file[0]);
        let _orderUUID = orderId;
        let payLoadCustomer = {
            file_type: "encryption_keys",
            file: result.split(",")[1],
            // "order": _orderUUID,
            name: file[0].name,
            file_size: file[0].size,
            audit: uuid,
        };
        let url = await getApiUrl();

        let apiUrl =
            url +
            process.env.REACT_APP_API_BASE_URL +
            "/orders/" +
            _orderUUID +
            "/audits/" +
            uuid +
            "/documents/";
        axios
            .post(apiUrl, payLoadCustomer, {})
            .then((res) => {
                console.log(res);
                payLoadCustomer["uuid"] = res.data.uuid;
                payLoadCustomer["name"] = res.data.name;
                payLoadCustomer["size"] = res.data.size;
                payLoadCustomer["type"] = res.data.file_type;
                // setFiles([...files, payLoadCustomer]);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const removeFile = async (uuid, index) => {
        if (uuid) {
            let _orderUUID = orderId;
            let deleteDocumentPayload = {uuid: uuid};
            let url = await getApiUrl();

            let removeDocumentApiUrl =
                url +
                process.env.REACT_APP_API_BASE_URL +
                `/orders/${_orderUUID}/audits/` +
                auditId +
                "/documents/" +
                deleteDocumentPayload.uuid +
                "/";
            axios
                .delete(removeDocumentApiUrl, deleteDocumentPayload, {})
                .then((res) => {
                    removeLocalFile(index);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            removeLocalFile(index);
        }
    };
    const removeLocalFile = (index) => {
        // let _files = files;
        // _files.splice(index, 1)
        // setFiles(_files);
        setFiles(
            files.filter((item, itemIndex) => {
                return itemIndex !== index;
            })
        );

        //   this.setState({
        //     tasks: reducedArr
        //   })
    };
    const submitReport = async () => {
        let _orderUUID = orderId;
        let payLoadCustomer = {
            executive_summary: document.getElementById("executive_summary").value,
            findings: document.getElementById("findings").value,
            recommendations: document.getElementById("recommendations").value,
            order: _orderUUID,
        };
        // let formDataPayload = objectToFormData(payLoadCustomer);
        let url = await getApiUrl();

        let apiUrl =
            url +
            process.env.REACT_APP_API_BASE_URL +
            "/orders/" +
            _orderUUID +
            "/audits/";
        axios
            .post(apiUrl, payLoadCustomer, {})
            .then((res) => {
                setAuditId(res.data.uuid);
                files.forEach((element) => {
                    uploadFile(element, res.data.uuid);
                });
                handleClose();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const [step, setStep] = useState(0);
    const [auditInfo, setAuditInfo] = useState(0);
    useEffect(() => {
        getAuditDetails();
        if (
            props.orderDetail.status == "audit_started" ||
            props.orderDetail.status == "audit_complete" ||
            props.orderDetail.status == "ingestion_started" ||
            props.orderDetail.status == "ingestion_complete"
        ) {
            setStep(1);
        }
    }, []);

    const getAuditDetails = async () => {
        let _orderUUID = props.orderDetail.uuid;
        let url = await getApiUrl();

        let apiUrl =
            url +
            process.env.REACT_APP_API_BASE_URL +
            "/orders/" +
            _orderUUID +
            "/audits/";
        axios
            .get(apiUrl)
            .then((res) => {
                // res.data['count'] = 1;
                setAuditInfo(res.data);
                if (res.data.results.length) {
                    setAuditId(res.data.results[0].uuid);

                    setExecutiveSummary(res.data.results[0]["executive_summary"]);
                    setFindings(res.data.results[0]["findings"]);
                    setRecommendations(res.data.results[0]["recommendations"]);

                    // document.getElementById("executive_summary").value = res.data.results[0]["executive_summary"];
                    // document.getElementById("findings").value = res.data.results[0]["findings"];
                    // document.getElementById("recommendations").value = res.data.results[0]["recommendations"];
                    getAuditDocuments(res.data.results[0].uuid);
                }
                // if (!res.data.count) {
                //     setStep(0);
                // } else {
                //     setStep(1);
                // }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getAuditDocuments = async (uuid) => {
        let _orderUUID = props.orderDetail.uuid;
        let url = await getApiUrl();

        let apiUrl =
            url +
            process.env.REACT_APP_API_BASE_URL +
            "/orders/" +
            _orderUUID +
            "/audits/" +
            uuid +
            "/documents/";
        axios
            .get(apiUrl)
            .then((res) => {
                if (res.data.results.length) {
                    setFiles(res.data.results);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const changeState = async () => {
        let _orderUUID = props.orderDetail.uuid;
        let url = await getApiUrl();

        let apiUrl =
            url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/";
        const payload = {
            status: "audit_started",
            uuid: _orderUUID,
            customer: props.orderDetail.customer,
        };
        axios
            .patch(apiUrl, payload, {
                /*             headers: {
                                    "Content-Type": "multipart/form-data"
                                }, */
            })
            .then((res) => {
                setStep(1);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            {step == 0 && (
                <StartAudit
                    changeState={changeState}
                    orderDetail={props.orderDetail}
                ></StartAudit>
            )}
            {step == 1 && (
                <AuditReport
                    orderDetail={props.orderDetail}
                    auditInfo={auditInfo}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                ></AuditReport>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    sx={{
                        ...style,
                        width: 700,
                        maxHeight: "75vh",
                        overflow: "auto",
                        padding: 5,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className={classes.heading}
                    >
                        Audit Report
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            p: 1,
                            m: 1,
                            justifyContent: "space-between",
                        }}
                    >
                        {files.map((item, i) => (
                            <Item
                                key={i}
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-center",
                                    flexDirection: "row",
                                    bgcolor: "#4E5152;",
                                    width: "203px",
                                    height: "62px",
                                    borderRadius: "12px",
                                    border: "1px solid var(--White, #FFF)",
                                }}
                            >
                                <Item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        p: 0,
                                        m: 0,
                                        alignItems: "center",
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.3333 28.3333H11.6667V25H23.3333M28.3333 21.6667H11.6667V18.3333H28.3333M28.3333 15H11.6667V11.6667H28.3333M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <Item
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography fontSize="14px">
                                            {item.length ? item[0].name : item?.name}
                                        </Typography>
                                        <Typography fontSize="14px" textAlign="start">
                                            {" "}
                                            {formatBytes(item.length ? item[0].size : item.size)}
                                        </Typography>
                                    </Item>
                                </Item>
                                {!isPreview && (
                                    <Item
                                        onClick={() =>
                                            removeFile(item.length ? item[0]?.uuid : item.uuid, i)
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <path
                                                d="M22.1654 7.47801L20.5204 5.83301L13.9987 12.3547L7.47703 5.83301L5.83203 7.47801L12.3537 13.9997L5.83203 20.5213L7.47703 22.1663L13.9987 15.6447L20.5204 22.1663L22.1654 20.5213L15.6437 13.9997L22.1654 7.47801Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </Item>
                                )}
                            </Item>
                        ))}
                    </Box>
                    <Box>
                        <Grid container direction="column" spacing={1}>
                            {!isPreview && (
                                <Grid
                                    item
                                    sx={{
                                        border: "1px solid var(--Gray-1, #C7CCD0)",
                                        padding: "20px",
                                        borderRadius: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "15px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <img
                                        style={{filter: "invert(1)"}}
                                        src="/assets/img/upload_icon.svg"
                                        alt=""
                                    />
                                    <Typography
                                        sx={{color: "#fff", fontWeight: "400 ", fontSize: "18px"}}
                                    >
                                        Upload the media
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        style={{
                                            backgroundColor: ButtonColor,
                                            color: "#3A3E3F",
                                            textTransform: "none",
                                            fontWeight: 400,
                                            borderRadius: "30px",
                                            width: "170px",
                                            height: "55px",
                                        }}
                                    >
                                        Upload File
                                        <FileInput
                                            type="file"
                                            multiple
                                            onChange={handleFileSelect}
                                        />
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Typography align="left" className={classes.heading}>
                                    Executive Summery
                                </Typography>
                            </Grid>
                            {/* <Grid item>
                                <Typography sx={{
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                }}>
                                    The tapes are being audited. While we looked at the tapes we found a few details along with a couple of issues.
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12}>
                                {!isPreview && (
                                    <TextField
                                        sx={{width: "100%"}}
                                        // label="Multiline"
                                        multiline
                                        rows={4}
                                        id="executive_summary"
                                    />
                                )}
                                {isPreview && (
                                    // <TextField
                                    //     sx={{ width: '100%' }}
                                    //     // label="Multiline"
                                    //     multiline
                                    //     rows={4}
                                    //     id="executive_summary"
                                    //     value={executiveSummary}
                                    // />
                                    <Typography
                                        align="left"
                                        sx={{
                                            width: "100%",
                                            color: "white",
                                            fontFamily: "Poppins",
                                            fontSize: "22px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }}
                                    >
                                        {executiveSummary}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item>
                                <Typography align="left" className={classes.heading}>
                                    Findings
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isPreview && (
                                    <TextField
                                        sx={{width: "100%"}}
                                        // label="Multiline"
                                        multiline
                                        rows={4}
                                        id="findings"
                                    />
                                )}
                                {isPreview && (
                                    //  <TextField
                                    //     sx={{ width: '100%' }}
                                    //     // label="Multiline"
                                    //     multiline
                                    //     rows={4}
                                    //     id="findings"
                                    //     value={findings}
                                    // />
                                    <Typography
                                        align="left"
                                        sx={{
                                            width: "100%",
                                            color: "white",
                                            fontFamily: "Poppins",
                                            fontSize: "22px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }}
                                    >
                                        {findings}
                                    </Typography>
                                )}
                            </Grid>
                            {/* <Grid item>
                                <Typography sx={{
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                }}>
                                    Total number of tapes were 1800 instead of 1500 that the user proposed. In total 120 tapes were inaccessible.
                                    <ul marginTop="50px">
                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>
                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>
                                    </ul>
                                    A total of 500 TB data was audited and 1.4 Tb was in unreadable sectors. 4 different tape types were used. 50 different formats of files were there and a virtual machine is still missing from the user end.
                                </Typography>
                            </Grid> */}
                            <Grid item>
                                <Typography align="left" className={classes.heading}>
                                    Recomendations
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isPreview && (
                                    <TextField
                                        sx={{width: "100%"}}
                                        // label="Multiline"
                                        multiline
                                        rows={4}
                                        id="recommendations"
                                    />
                                )}
                                {isPreview && (
                                    // <TextField
                                    //     sx={{ width: '100%' }}
                                    //     // label="Multiline"
                                    //     multiline
                                    //     rows={4}
                                    //     id="recommendations"
                                    //     value={recommendations}
                                    // />
                                    <Typography
                                        align="left"
                                        sx={{
                                            width: "100%",
                                            color: "white",
                                            fontFamily: "Poppins",
                                            fontSize: "22px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }}
                                    >
                                        {recommendations}
                                    </Typography>
                                )}
                            </Grid>
                            {/* <Grid item>
                                <Typography sx={{
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                }}>
                                    <ul>
                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>
                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>

                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>
                                        <li>80 of the 120 tapes were found to be damaged and unreadable</li>

                                    </ul>
                                </Typography>
                            </Grid> */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "25px",
                                }}
                            >
                                <StyledButtonOutlined
                                    variant="contained"
                                    onClick={handleClose}
                                    sx={{
                                        width: "190px",
                                        height: "60px",
                                    }}
                                >
                                    Cancel
                                </StyledButtonOutlined>
                                {!isPreview && (
                                    <StyledButtonContained
                                        variant="contained"
                                        onClick={submitReport}
                                        sx={{
                                            width: "190px",
                                            height: "60px",
                                            borderRadius: "30px",
                                        }}
                                    >
                                        Submit Report
                                    </StyledButtonContained>
                                )}
                            </div>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            {/* <AuditReport></AuditReport> */}
        </>
    );
};
export const StartAudit = (props) => {
    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--White, #FFF)",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "28px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                    }}
                >
                    The tapes have been delivered to respective locations. Click below to
                    start the Audit
                </Typography>
                <br/>
                <br/>
                <Button
                    disabled={
                        props.orderDetail.status == "audit_started" ||
                        props.orderDetail.status == "audit_complete" ||
                        props.orderDetail.status == "ingestion_started" ||
                        props.orderDetail.status == "ingestion_complete"
                    }
                    onClick={() => {
                        props.changeState();
                    }}
                    style={{
                        width: "220px",
                        backgroundColor: ButtonColor, //#3A3E3F
                        color: "#3A3E3F",
                        borderRadius: "30px",
                        height: "60px",
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: 400,
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    Start Audit
                </Button>
            </Box>
        </>
    );
};
export const AuditReport = (props) => {
    return (
        <>
            {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          m: 1,
          bgcolor: "#4E5152;",
          borderRadius: 1,
          justifyContent: "space-between",
        }}
      >
        <Item>
          {" "}
          Shipments
          <Item
            sx={{
              display: "flex",
              alignItems: "flex-center",
              flexDirection: "column",
              p: 1,
              m: 1,
              bgcolor: "#4E5152;",
              borderRadius: 1,
            }}
          >
            <Item> Received</Item>
            <Item> Expected</Item>
          </Item>
        </Item>
        <Item>
          {" "}
          Boxs
          <Item
            sx={{
              display: "flex",
              alignItems: "flex-center",
              flexDirection: "column",
              p: 1,
              m: 1,
              bgcolor: "#4E5152;",
              borderRadius: 1,
            }}
          >
            <Item> 20</Item>
            <Item> 20</Item>
          </Item>
        </Item>
        <Item>
          {" "}
          Tapes
          <Item
            sx={{
              display: "flex",
              alignItems: "flex-center",
              flexDirection: "column",
              p: 1,
              m: 1,
              bgcolor: "#4E5152;",
              borderRadius: 1,
            }}
          >
            <Item> 2000</Item>
            <Item> 1996</Item>
          </Item>
        </Item>
        <Item>
          {" "}
          LTO1
          <Item
            sx={{
              display: "flex",
              alignItems: "flex-center",
              flexDirection: "column",
              p: 1,
              m: 1,
              bgcolor: "#4E5152;",
              borderRadius: 1,
            }}
          >
            <Item> 1200</Item>
            <Item> 1200</Item>
          </Item>
        </Item>
        <Item>
          {" "}
          LTO2
          <Item
            sx={{
              display: "flex",
              alignItems: "flex-center",
              flexDirection: "column",
              p: 1,
              m: 1,
              bgcolor: "#4E5152;",
              borderRadius: 1,
            }}
          >
            <Item> 600</Item>
            <Item> 500</Item>
          </Item>
        </Item>
      </Box> */}
            {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          m: 1,
          bgcolor: "#4E5152",
          borderRadius: 1,
          justifyContent: "space-between",
        }}
      >
       <Item
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 1,
            m: 1,
            bgcolor: "#4E5152",
            borderRadius: 1,
            justifyContent: "space-between",
          }}
        >
          <Item>
            {" "}
            <InputBase
              type="text"
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#3A3E3F" }}>
                  <SearchIcon sx={{ marginRight: "4px" }} />
                </InputAdornment>
              }
              sx={{
                "&.MuiInputBase-input": {
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#3A3E3F",
                },
                width: "241px",
                height: "41px",
                background: "#E9EEF44D",
                color: "#3A3E3F",
                borderRadius: "20px",
                paddingLeft: "20px",
                border: "none",
                outline: "none",
              }}
            />
          </Item>
          
     
        <Box>
          <div style={{ height: 400, width: "100%" }}> */}
            {/* <Item>
            <Button
              type="submit"
              size="large"
              fullWidth={true}
              variant="contained"
              style={{
                width: "inherit",
                backgroundColor: ButtonColor,
                color: "#3A3E3F",
                height: "inherit",
                textTransform: "none",
                fontWeight: 700,
                borderRadius: "6px",
                gap: "12px",
              }}
            >
              <img src="/assets/img/upload_icon.svg" />
              Export
            </Button>
          </Item> */}
            <EnhancedTable
                auditInfo={props.auditInfo}
                handleOpen={props.handleOpen}
                handleClose={props.handleClose}
                orderDetail={props.orderDetail}
            ></EnhancedTable>
            {/* </div>
        </Box>
      </Box> */}
        </>
    );
};

function createData(id, name, calories, fat, carbs, protein) {
    return {
        id,
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData(1, "Cupcake", 305, 3.7, 67, 4.3),
    createData(2, "Donut", 452, 25.0, 51, 4.9),
    createData(3, "Eclair", 262, 16.0, 24, 6.0),
    createData(4, "Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData(5, "Gingerbread", 356, 16.0, 49, 3.9),
    createData(6, "Honeycomb", 408, 3.2, 87, 6.5),
    createData(7, "Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData(8, "Jelly Bean", 375, 0.0, 94, 0.0),
    createData(9, "KitKat", 518, 26.0, 65, 7.0),
    createData(10, "Lollipop", 392, 0.2, 98, 0.0),
    createData(11, "Marshmallow", 318, 0, 81, 2.0),
    createData(12, "Nougat", 360, 19.0, 9, 37.0),
    createData(13, "Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Dessert (100g serving)",
    },
    {
        id: "calories",
        numeric: true,
        disablePadding: false,
        label: "Calories",
    },
    {
        id: "fat",
        numeric: true,
        disablePadding: false,
        label: "Fat (g)",
    },
    {
        id: "carbs",
        numeric: true,
        disablePadding: false,
        label: "Carbs (g)",
    },
    {
        id: "protein",
        numeric: true,
        disablePadding: false,
        label: "Protein (g)",
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    padding="checkbox"
                    sx={{
                        background: "#4e5152",
                        border: "none",
                        color: "white",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    }}
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{
                            border: "none",
                            background: "#4e5152",
                            color: "white",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        }}
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        > */}
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                        ) : null}
                        {/* </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const {numSelected} = props;

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const EnhancedTable = (props) => {
    const classes = useStyles();
    const [allRestoreList, setAllRestoreList] = useState([]);
    const [restoreData, setRestoreData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [tapeCount, setTapeCount] = useState(0);
    const [completedTap, setCompletedTap] = useState(0);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);

    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const params = useParams();

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        let _slice = allRestoreList.slice(
            (newPage - 1) * 10,
            (newPage - 1) * 10 + 10
        );
        setRestoreData(_slice);
    };

    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, tapeCount);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [order, orderBy, page, rowsPerPage]
    );
    const Textarea = styled(BaseTextareaAutosize)(
        ({theme}) => `
        width: 900px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: red;
        background: purple;
      
      `
    );
    let state = [
        {
            key: "verified_and_completed",
            value: "Audit Awaited",
        },
        {
            key: "audit_started",
            value: "Audit Started",
        },
        {
            key: "audit_complete",
            value: "Audit Complete",
        },
        {
            key: "ingestion_started",
            value: "Audit Complete",
        },
        {
            key: "ingestion_complete",
            value: "Audit Complete",
        },
    ];

    function mapOrderStep(_state) {
        if (_state) {
            let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
            return step?.value ? step.value : _state;
        } else {
            return _state;
        }
    }

    // const handleFileSelect = (e) => {
    //     if (e.target.files) {
    //         let selectedFiles = Array.from(e.target.files);
    //         let _files = files;
    //         _files.push(selectedFiles);
    //         setFiles(_files)
    //         // uploadFile(selectedFiles, 'signed_contract');

    //     }
    // };
    // const toBase64 = file => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = reject;
    // })
    // async function uploadFile(file, uuid) {
    //     const result = await toBase64(file[0]);
    //     let _orderUUID = window?.location?.href?.split("uuid=")[1];
    //     let payLoadCustomer = {
    //         "file_type": "encryption_keys",
    //         "file": result.split(",")[1],
    //         "order": _orderUUID,
    //         "name": file[0].name,
    //         "file_size": file[0].size,
    //     }
    //     let apiUrl =  process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/audits/' + uuid + '/';
    //     axios.post(apiUrl, payLoadCustomer, {}).then((res) => {
    //         console.log(res)
    //         payLoadCustomer['uuid'] = res.data.uuid;
    //         payLoadCustomer['name'] = file[0].name;
    //         payLoadCustomer['size'] = file[0].size;
    //         payLoadCustomer['type'] = file[0].type;
    //         setFiles([...files, payLoadCustomer]);
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }
    // const removeFile = (uuid, index) => {
    //     if (uuid) {
    //         let _orderUUID = window?.location?.href?.split("uuid=")[1];
    //         let deleteDocumentPayload = { uuid: uuid }
    //         let removeDocumentApiUrl =  process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/audits/` + deleteDocumentPayload.uuid + "/";
    //         axios.delete(removeDocumentApiUrl, deleteDocumentPayload, {}).then((res) => {
    //             removeLocalFile(index);
    //         }).catch(function (error) {
    //             console.log(error);
    //         });
    //     } else {
    //         removeLocalFile(index);
    //     }
    // }
    // const removeLocalFile = (index) => {
    //     let _files = files;
    //     _files.splice(index, 1)
    //     setFiles(_files);
    // }
    // const submitReport = () => {
    //     let _orderUUID = window?.location?.href?.split("uuid=")[1];
    //     let payLoadCustomer = {
    //         "executive_summary": document.getElementById("executive_summary").value,
    //         "findings": document.getElementById("findings").value,
    //         "recommendations": document.getElementById("recommendations").value,
    //         "order": _orderUUID,
    //     }
    //     // let formDataPayload = objectToFormData(payLoadCustomer);
    //     let apiUrl =  process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/audits/';
    //     axios.post(apiUrl, payLoadCustomer, {}).then((res) => {
    //         files.forEach(element => {
    //             uploadFile(element, res.data.uuid)
    //         });
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }
    // const formatBytes = (bytes, decimals = 2) => {
    //     if (!+bytes) return '0 Bytes'

    //     const k = 1024
    //     const dm = decimals < 0 ? 0 : decimals
    //     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    //     const i = Math.floor(Math.log(bytes) / Math.log(k))

    //     return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    // }
    const completeAudit = async () => {
        let _orderUUID = params.orderId;
        let url = await getApiUrl();

        let apiUrl =
            url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/";
        const payload = {
            status: "audit_complete",
            uuid: _orderUUID,
            customer: props.orderDetail.customer,
        };
        axios
            .patch(apiUrl, payload, {
                /*             headers: {
                                    "Content-Type": "multipart/form-data"
                                }, */
            })
            .then((res) => {
                // setStep(1);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const [logInUser, setLogInUser] = React.useState();

    React.useEffect(() => {
        setLogInUser(getLoginUser());
        // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
        let payload = {
            type: "man_item_list",
            request: {
                //company_id:props.orderDetail.tapeark_job_id,
                // limit: 5,
                job_id: props.orderDetail.tapeark_job_id,
                // company_id: props?.customer?.arkbridge_customer_id ? props?.customer?.arkbridge_customer_id : logInUser?.user?.arkbridge_customer_id
            },
        };

        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    // debugger
                    let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                    dynamicObject.forEach(i => {
                            if (i?.data && Array.isArray(i.data) && i.data?.length) {
                                dynamicArray = i.data;
                            }
                        }
                    )
                    setAllRestoreList(
                        dynamicArray.filter((i) => i.job_id == props.orderDetail.tapeark_job_id)
                    );
                    setRestoreData(
                        dynamicArray
                            .filter((i) => i.job_id == props.orderDetail.tapeark_job_id)
                            .slice(0, 10)
                    );
                    setTotalPages(
                        Math.ceil(
                            dynamicArray.filter((i) => i.job_id == props.orderDetail.tapeark_job_id)
                                .length / 10
                        )
                    );
                    setTapeCount(
                        dynamicArray.filter((i) => i.job_id == props.orderDetail.tapeark_job_id).length
                    );
                    setCompletedTap(
                        dynamicArray
                            .filter((i) => i.job_id == props.orderDetail.tapeark_job_id)
                            .filter((i) => i.status_id == 2).length
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <>
            <Box padding={6} minHeight={"100vh"}>
                <Typography
                    sx={{
                        flex: "1 1 100%",
                        pt: 2,
                        textAlign: "left",
                        px: 2,
                        fontFamily: "Poppins",
                        fontWeight: 700,
                        fontSize: "22px",
                        color: "#FFFFFF",
                    }}
                    id="tableTitle"
                    component="div"
                >
                    {mapOrderStep(props.orderDetail.status)}
                </Typography>
                <Typography
                    sx={{
                        flex: "1 1 100%",
                        pt: 2,
                        textAlign: "left",
                        px: 2,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "#FFFFFF",
                    }}
                    id="tableTitle"
                    component="div"
                >
                    You will receive an audit report providing information about the details
                    of your tapes.
                </Typography>

                <div style={{position: "relative"}}>
                    <CustomSlider
                        disabled
                        value={isNaN(((completedTap / tapeCount) * 100).toFixed(2)) ? 0 : ((completedTap / tapeCount) * 100).toFixed(2)}
                        valueLabelDisplay="on"
                        valueLabelFormat={(v) => `${v}%`}
                    />
                    <div className={classes.thumbBoxStyle} style={{
                        marginLeft: `calc(${isNaN(((completedTap / tapeCount) * 100).toFixed(2)) ? 0 : ((completedTap / tapeCount) * 100).toFixed(2)}% - 40px)`
                    }}>
                        <Stack direction={"column"}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    fontSize: "22px",
                                    lineHeight: "33px",
                                    color: "#FFC540",
                                }}
                            >
                                {completedTap}/{tapeCount}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    lineHeight: "27px",
                                    color: "#FFFFFF",
                                }}
                            >
                                Tapes Audited
                            </Typography>
                        </Stack>
                    </div>
                </div>

                <CommonTable
                    startIndex={startIndex}
                    endIndex={endIndex}
                    handlePageChange={handlePageChange}
                    restoreData={restoreData}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    tapeCount={tapeCount}
                    classes={classes}

                />
                {/* <div className="auditTable">
      <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14 , fontWeight:"700" }}
                    >
                      Tape Volser
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape type
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape format
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Data Volume
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Retention Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Created Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Comments
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape Photgrapgh
                    </Typography>
                  </TableCell>
                <TableCell className={classes.columnHeader}>
                <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                     Status
                    </Typography>
                </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& > *": {
                    backgroundColor: "#3A3E3F",
                    borderRadius: "8px",
                  },
                }}
              >
                {restoreData
                  // .slice(0, 8)
                  .map((row, index) => (
                    <TableRow
                      key={row.item_id}
                      sx={{
                        "& > *": {
                          border: "unset",
                        },
                      }}
                    >
                      <TableCell>
                        <Typography
                          className="restore-name"
                          key={row.item_id}
                          id={`restore-name-${index}`}
                          sx={{
                            color: "#FFFFFF",
                            fontSize: 14,
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: row.selected ? 700 : 400,
                            whiteSpace: "pre",
                          }}
                        >
                          { row.item_volser}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.mediatype}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.backup_format}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.size}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            padding={2}
            style={{
              width: "100%",
              border: "1px solid #C7CCD0",
              minWidth: "55%",
            }}
          >
            {/* <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing 1-10 of 100 files`}
              </Typography>
            </Grid> */}
                {/* <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing ${startIndex}-${endIndex} of ${tapeCount} Customers`}
              </Typography>
            </Grid>
            <Grid item>
              <Pagination
                component="div"
                rowsPerPage={10}
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    width: "30px",
                    height: "30px",
                    border: "1px solid #FFFFFF",
                    background: "#3A3E3F",
                    color: "#FFFFFF",
                    margin: "0 4px",
                    "&.Mui-selected": {
                      background: "#FFC540",
                      color: "#3A3E3F",
                    },
                  },
                  "& .MuiPaginationItem-page.Mui-selected:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-page:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                    width: "100px",
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    background: "#3A3E3F",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover":
                    {
                      background: "#FFC540",
                      color: "#3A3E3F",
                    },
                }}
                renderItem={(item) => <PaginationItem {...item} />}
              />
            </Grid>
          </Grid>
        </Grid>
        </div> */}
                <div style={{display: "flex", justifyContent: "end"}}>
                    <StyledButtonContained
                        onClick={completeAudit}
                        disabled={props.orderDetail.status == 'audit_complete'}
                        variant="contained"
                        sx={{
                            width: "180px",
                            height: "50px",
                            marginTop: "6px"
                        }}
                    >
                        Complete Audit
                    </StyledButtonContained>

                </div>

            </Box>
        </>
    );
};

const useStyles = makeStyles({
    // selectLabel: {
    //     '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    //         color: '#C7CCD0'
    //     },
    //     '& .MuiInputLabel-root.Mui-focused': {
    //         color: '#C7CCD0'
    //     },
    //     "& .MuiSelect-icon": {
    //         color: '#C7CCD0'
    //     },
    //     '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#C7CCD0'
    //     },
    //     '& .MuiOutlinedInput-input': {
    //         color: '#C7CCD0'
    //     },
    //     '& .MuiInputLabel-root': {
    //         color: '#C7CCD0'
    //     },
    //     '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#C7CCD0'
    //     }
    // },
    selectLabel: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "8px",
                borderColor: "#C7CCD0",
            },
            "&:hover fieldset": {
                borderColor: "#C7CCD0",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#C7CCD0",
            },
        },
        "& .MuiOutlinedInput-input": {
            color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
            color: "rgba(255, 255, 255, 0.5)",
            "&.Mui-focused": {
                color: "#FFC540",
            },
        },
        "& svg": {
            color: "#FFFFFF",
        },
    },
    heading: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#C7CCD0",
        lineHeight: "24px",
        marginBottom: "12px",
    },
    columnHeader: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    thumbBoxStyle: {
        width: "91px",
        height: "95px",
        borderRadius: "8px",
        border: "1px solid #FFC540",
        position: "absolute",
        top: "calc(100% + 8px)",
        // left: `calc(${(value / 100) * 100}% - 45.5px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: PrimaryColor,
        zIndex: 1,
    }
});
