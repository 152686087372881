import React, {useEffect, useState} from "react";
import Slider from "@mui/material/Slider";
import {PrimaryColor} from "../theme/theme";
import {Stack,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {StyledButtonContained} from "../../views/Admin/AccountCreation/accountCreation";
import axios from "axios";
import {formats} from "../../global";
import {getLoginUser} from "../../LocalStorageService";
import {useFeatures} from "../../views/FeaturesProvider/featuresProvider";
import {TapeDetails} from "../../views/TapeDetails/tapeDetails";
import DataTable from "../../views/DataTable/DataTable";
import {TAPE_AUDIT_TABLE_COLUMNS} from "./auditTableCustomization";

const CustomSlider = styled(Slider)({
    color: "#D9D9D9",
    height: 15,
    "& .MuiSlider-track": {
        height: 15,
        border: "none",
        color: "#FFC540",
    },
    "& .MuiSlider-thumb": {
        height: 32,
        width: 32,
        backgroundColor: "#FFC540",
        border: "none",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: 1.2,
        fontSize: 16,
        background: "unset",
        padding: 0,
        width: 48,
        height: 48,
        color: "#3A3E3F",
        borderRadius: "50% 50% 50% 50%",
        backgroundColor: "#FFC540",
        transformOrigin: "bottom left",
        "&:before": {display: "none"},
        "&.MuiSlider-valueLabelOpen": {
            transform: "translateY(0%)",
        },
        "& > *": {},
    },
});
const useStyles = makeStyles({
    rootMenuItem: {
        "&$selected": {
            backgroundColor: "red",
            "&:hover": {
                backgroundColor: "green",
                borderRadius: 12,
                border: "1px solid var(--White, #FFF)",
            },
        },
        "&:hover": {
            backgroundColor: "transparent",
            borderRadius: 12,
            border: "1px solid var(--White, #FFF)",
        },
    },
    highlight: {
        background: "#FFC540",
        color: "#3A3E3F",
    },
    selectLabel: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "8px",
                borderColor: "#C7CCD0",
            },
            "&:hover fieldset": {
                borderColor: "#C7CCD0",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#C7CCD0",
            },
        },
        "& .MuiOutlinedInput-input": {
            color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
            color: "rgba(255, 255, 255, 0.5)",
            "&.Mui-focused": {
                color: "#FFC540",
            },
        },
        "& svg": {
            color: "#FFFFFF",
        },
    },
    checkedColr: {
        "&.Mui-checked": {
            color: "red",
        },
    },
});

function dictionaryObject(item, mentioned, actual) {
    return {item, mentioned, actual};
}

const mockDataForAuditReport = [
    dictionaryObject("Tapes", 4, 3),
    dictionaryObject("Data (Pb)", 6.8, 6),
    dictionaryObject("Tape Types", 10, 10),
    dictionaryObject("Formats", 11, 12),
];

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3A3E3F",
        color: "#F9F9F9",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&.MuiTableRow-root": {
        "&:nth-of-type(odd)": {
            backgroundColor: "#979797",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#3A3E3F",
            border: "1px solid #FFC540",
        },
        "& > *": {
            color: "#FFFFFF",
        },
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const Audit = ({value, orderDetail}) => {
    const classes = useStyles();
    const [restoreData, setRestoreData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const userDetails = getLoginUser();
    const [isAdmin, setIsAdmin] = useState(false);
    const features = useFeatures()
    const [totalFetched, setTotalFetched] = useState(false);
    const [entriesCount, setEntriesCount] = useState(0);
    const [selectedTapeId, setSelectedTapeId] = React.useState();
    const [openTapeDetails, setOpenTapeDetails] = React.useState(false);
    const [completedTap, setCompletedTap] = useState(0);

    const selectTapeForDetailing = (row) => {
        setSelectedTapeId(row?.item_id);
        setOpenTapeDetails(true);
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        getTapeData(isAdmin, newPage);
    };

    let stateMnemonicToName = {
        "verified_and_completed": "Audit Awaited",
        "audit_started": "Audit Started",
        "audit_complete": "Audit Complete",
        "ingestion_started": "Audit Complete",
        "ingestion_complete": "Audit Complete"
    };

    useEffect(() => {
        if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) {
            setIsAdmin(true);
            if (!totalFetched) {
                getTapeCount(true);
                setTotalFetched(true);
            }
        } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {
            setIsAdmin(false);
            if (!totalFetched) {
                getTapeCount(false);
                setTotalFetched(true);
            }
        }
    }, [features]);

    /**
     * Fetches from the SaaS API the count of tapes.
     * Side effects: updates the internal states totalPages (multiple of 10), entriesCount and calls getTapeData for page 1.
     * If nothing is returned, defaults to 0.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @returns {void}
     */
        // man_item_count
    const getTapeCount = (_isAdmin) => {
            let payload = {
                type: "man_item_count",
                request: {
                    cid: userDetails.user.arkbridge_customer_id,
                    job_id: orderDetail.tapeark_job_id,
                    type: "man_item_count"
                },
            };
            axios
                .post(formats, payload)
                .then((response) => {
                    if (response?.status == 200) {
                        let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
                        setTotalPages(Math.ceil(_count / 10));
                        setEntriesCount(_count);
                        getTapeData(_isAdmin, 1);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log("getTapeCount error", userDetails.user);
                });
        };

    /**
     * Fetches from the SaaS API the tapes' data. It fetches the data for max 10 tapes of a specific page.
     * Side effects: updates the internal states allRestoreList, restoreData and calls mapAddedRows for the first max 10 items returned.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {number} pageNumber - Page number
     */
    const getTapeData = (_isAdmin, pageNumber) => {
        let payload = {
            type: "man_item_list",
            request: {
                cid: userDetails.user.arkbridge_customer_id,
                job_id: orderDetail.tapeark_job_id,
                type: "man_item_list",
                page_number: pageNumber - 1,
                page_size: 10
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    let dynamicArray;
                    let dynamicObject;
                    if (!_isAdmin) {
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i =>
                            dynamicArray = i.data)
                    } else {
                        dynamicArray = [];
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject[1].forEach(i => dynamicArray.push(i))
                    }
                    setRestoreData(dynamicArray.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("getTapeData error", userDetails.user);
            });
    };

    // setCompletedTap(
    //         dynamicArray
    //           .filter((i) => i.job_id == orderDetail.tapeark_job_id)
    //           .filter((i) => i.status_id == 2).length
    //       );

    const getTapesAuditedSliderConstruct = () => {
        const sx = {
            flex: "1 1 100%",
            pt: 2,
            textAlign: "left",
            px: 2,
            fontFamily: "Poppins",
            color: "#FFFFFF"
        };

        const getSX = (fontWeight, fontSize) => {
            return {...sx, fontWeight, fontSize};
        }

        const auditMainTitle = (
            <Typography
                sx={getSX(700, "22px")}
                id="tableTitle"
                component="div">
                {orderDetail.status ? (
                        stateMnemonicToName[String(orderDetail.status).toLowerCase()] ?
                            stateMnemonicToName[String(orderDetail.status).toLowerCase()]
                            : String(orderDetail.status))
                    : orderDetail.status}
            </Typography>
        );

        const auditSubTitle = (
            <Typography
                sx={getSX(400, "18px")}
                id="tableDescription"
                component="div"
            >
                You will receive an audit report providing information about the details
                of your tapes.
            </Typography>
        );

        return (
            <>
                <TapeDetails open={openTapeDetails} closePopup={() => {
                    setOpenTapeDetails(false)
                }} tapeId={selectedTapeId}></TapeDetails>

                {auditMainTitle}
                {/*{auditSubTitle}*/}
                <div style={{position: "relative"}}>
                    <CustomSlider
                        disabled
                        value={isNaN(((completedTap / entriesCount) * 100).toFixed(2)) ? 0 : ((completedTap / entriesCount) * 100).toFixed(2)}
                        valueLabelDisplay="on"
                        valueLabelFormat={(v) => `${v}%`}
                    />
                    <div style={{
                        width: "91px",
                        height: "95px",
                        borderRadius: "8px",
                        border: "1px solid #FFC540",
                        position: "absolute",
                        top: "calc(100% + 8px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: PrimaryColor,
                        zIndex: 1,
                        marginLeft: `calc(${isNaN(((completedTap / entriesCount) * 100).toFixed(2)) ? 0 : ((completedTap / entriesCount) * 100).toFixed(2)}% - 40px)`
                    }}>
                        <Stack direction={"column"}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    fontSize: "22px",
                                    lineHeight: "33px",
                                    color: "#FFC540",
                                }}
                            >
                                {completedTap}/{entriesCount}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    lineHeight: "27px",
                                    color: "#FFFFFF",
                                }}
                            >
                                Tapes Audited
                            </Typography>
                        </Stack>
                    </div>
                </div>
            </>
        );
    };

    return (
        <Box padding={6} minHeight={"100vh"}>
            {getTapesAuditedSliderConstruct()}
            <div className="auditTable">
                <DataTable
                    data={restoreData.length > 0 ? restoreData : []}
                    tableColumns={TAPE_AUDIT_TABLE_COLUMNS["Standard tapes"]}
                    dataContainsFormatting={false}
                    componentsClickCallBackMap={{"tape_photo": selectTapeForDetailing}}
                    classes={classes}
                    showPagination={true}
                    entriesCount={entriesCount}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChangeCallback={handlePageChange}
                />
            </div>

            {/*Audit Report Table. Disabled for the moment. Requires later in depth impl.*/}
            {/*{value === 100 && (*/}
            {/*    <Box*/}
            {/*        sx={{*/}
            {/*            display: "flex",*/}
            {/*            flexDirection: "column",*/}
            {/*            alignItems: "center", // Center horizontally*/}
            {/*            textAlign: "center",*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {" "}*/}
            {/*        /!* displayed at value=100% *!/*/}
            {/*        <TableContainer*/}
            {/*            sx={{maxWidth: 700, mt: 18, border: "1px solid #EAEAEA"}}*/}
            {/*            component={Paper}*/}
            {/*        >*/}
            {/*            <Typography*/}
            {/*                sx={{*/}
            {/*                    flex: "1 1 100%",*/}
            {/*                    pt: 2,*/}
            {/*                    textAlign: "left",*/}
            {/*                    px: 2,*/}
            {/*                    fontFamily: "Poppins",*/}
            {/*                    fontWeight: 700,*/}
            {/*                    fontSize: "22px",*/}
            {/*                    color: "#FFFFFF",*/}
            {/*                    backgroundColor: PrimaryColor,*/}
            {/*                }}*/}
            {/*                id="tableTitle"*/}
            {/*                component="div"*/}
            {/*            >*/}
            {/*                Audit Report*/}
            {/*            </Typography>*/}
            {/*            <Table aria-label="customized table">*/}
            {/*                <TableHead>*/}
            {/*                    <TableRow>*/}
            {/*                        <StyledTableCell>Item</StyledTableCell>*/}
            {/*                        <StyledTableCell align="right">Mentioned</StyledTableCell>*/}
            {/*                        <StyledTableCell align="right">Actual</StyledTableCell>*/}
            {/*                    </TableRow>*/}
            {/*                </TableHead>*/}
            {/*                <TableBody>*/}
            {/*                    {mockDataForAuditReport.map((row) => (*/}
            {/*                        <StyledTableRow key={row.name}>*/}
            {/*                            <StyledTableCell component="th" scope="row">*/}
            {/*                                {row.item}*/}
            {/*                            </StyledTableCell>*/}
            {/*                            <StyledTableCell align="right">*/}
            {/*                                {row.mentioned}*/}
            {/*                            </StyledTableCell>*/}
            {/*                            <StyledTableCell align="right">*/}
            {/*                                {row.actual}*/}
            {/*                            </StyledTableCell>*/}
            {/*                        </StyledTableRow>*/}
            {/*                    ))}*/}
            {/*                </TableBody>*/}
            {/*            </Table>*/}
            {/*        </TableContainer>*/}
            {/*        /!* Button *!/*/}
            {/*        <StyledButtonContained*/}
            {/*            variant="contained"*/}
            {/*            sx={{*/}
            {/*                width: "280px",*/}
            {/*                height: "60px",*/}
            {/*                mt: 4,*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            Download Full Report*/}
            {/*        </StyledButtonContained>*/}
            {/*    </Box>*/}
            {/*)}*/}
        </Box>
    );
};
