export const TYPICAL_COLUMN_UI_CLASS = {
    "volsers": "str",
    "name": "str",
    "backed_up": "datetime",
    "backup_software_id": "int",
    "backup_software_name": "str",
    "backup_source_id": "int",
    "retention_policy_id": "int",
    "size": "data_size",
    "version_id": "int",
    "item_id": "int",
    "job_id": "int",
    "company_id": "int",
    "created": "datetime",
    "media_type_id": "int",
    "status_id": "int",
    "retention_date	": "datetime",
    "backup_format_id": "int",
    "media_native_capacity": "data_size",
    "data_volume": "data_size",
};